import {Filter} from '../types/filter';
import {IasAppInvalidTrafficFilter} from './ias-app-invalid-traffic-filter';
import {IasSiteInvalidTrafficFilter} from './ias-site-invalid-traffic-filter';

type IasInvalidTrafficFilter = IasAppInvalidTrafficFilter | IasSiteInvalidTrafficFilter;

function isIasInvalidTrafficFilter(filter: Filter): filter is IasInvalidTrafficFilter {
    return filter instanceof IasAppInvalidTrafficFilter || filter instanceof IasSiteInvalidTrafficFilter;
}

export {IasInvalidTrafficFilter, isIasInvalidTrafficFilter};
