import {CoreCookieService} from '@angular-clan/core';
import {HttpRequest} from '@angular/common/http';

// Add token to every api request if available except for login
function getAdditionDspHeaders(
    request: HttpRequest<unknown>,
    cookieService: CoreCookieService,
): IHeaders {
    const additionalHeaders: IHeaders = {};
    const token: string | null = cookieService.get('token');
    if (token && !request.url.includes('/auth')) {
        additionalHeaders.Authorization = token;
    }

    return additionalHeaders;
}

interface IHeaders {
    [name: string]: string | Array<string>;
}

export {getAdditionDspHeaders};
