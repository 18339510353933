import {CoreCookieService} from '@angular-clan/core';
import {HttpRequest} from '@angular/common/http';
import {getAdditionDspHeaders} from '../get-addition-dsp-headers';

/**
 * To guarantee that we keep the same date format which we have received
 * For that we need to check if in the date string the delimiter "T" (e.g. 2019-07-08T16:09:34) exists.
 */
export function createDspInterceptionRequest(
    request: HttpRequest<unknown>,
    cookieService: CoreCookieService,
): HttpRequest<unknown> {
    return request.clone({
        setHeaders: getAdditionDspHeaders(request, cookieService),
        params: request.params,
    });
}
