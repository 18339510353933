import {Ssps} from '../ssp';
import {CampaignTypes} from './campaign-types';

function getSspIdsByCampaignType(type: CampaignTypes): Array<Ssps> {
    const campaignTypesBySsps: Record<CampaignTypes, Array<Ssps>> = {
        [CampaignTypes.Display]: [
            Ssps.YIELDLAB,
            Ssps.GOOGLE,
            Ssps.APPNEXUS,
            Ssps.IMPROVEDIGITAL,
            Ssps.SMART_ADSERVER,
            Ssps.SMARTSTREAM,
            Ssps.RUBICON,
            Ssps.YAHOO,
            Ssps.STICKY_ADS,
            Ssps.SPOTXCHANGE,
            Ssps.ADSCALE,
            Ssps.SMARTCLIP,
            Ssps.PERMODO,
            Ssps.ADSWIZZ,
            Ssps.TEADS,
            Ssps.PUBMATIC,
            Ssps.OATH_VIDEO,
            Ssps.ORBIDDER,
            Ssps.OUTBRAIN,
        ],
        [CampaignTypes.AddressableTv]: [Ssps.YIELDLAB, Ssps.SMARTCLIP, Ssps.STICKY_ADS],
        [CampaignTypes.DigitalOutOfHome]: [Ssps.AYUDA, Ssps.YIELDLAB, Ssps.ADSCALE, Ssps.VIOOH, Ssps.ATG, Ssps.BROADSIGN],
        [CampaignTypes.Radio]: [Ssps.ADSWIZZ, Ssps.RUBICON, Ssps.APPNEXUS],
        [CampaignTypes.Pep]: [Ssps.YIELDLAB],
        [CampaignTypes.Redirect]: [],
        [CampaignTypes.ProgrammaticTv]: [Ssps.ADITION_TV],
        [CampaignTypes.AddressableTVSpotBundle]: [Ssps.YIELDLAB, Ssps.STICKY_ADS],
    };

    return campaignTypesBySsps[type];
}

export {getSspIdsByCampaignType};
