import {NgClass} from '@angular/common';
import {Component, input, InputSignal} from '@angular/core';

@Component({
    selector: 'commons-content',
    templateUrl: './content.html',
    styleUrl: './content.scss',
    standalone: true,
    imports: [NgClass],
})
class CommonsContentComponent {
    public limitWidth: InputSignal<boolean> = input<boolean>(false);
    public fillHeight: InputSignal<boolean> = input<boolean>(false);
    public minHeight: InputSignal<number | null> = input<number | null>(null);
}

export {CommonsContentComponent};
