enum FilterTypes {
    ADSQUARE_GEO_CONTEXTUAL = 'ADSQUARE_GEO_CONTEXTUAL',
    AUCTION_TYPE = 'AUCTION_TYPE',
    AUDIO = 'AUDIO',
    BROWSER = 'BROWSER',
    CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE = 'CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE',
    CAMPAIGN_DOOH_TRAFFIC_TYPE = 'CAMPAIGN_DOOH_TRAFFIC_TYPE',
    CAMPAIGN_PEP_SSP = 'CAMPAIGN_PEP_SSP',
    CAPPING = 'CAPPING',
    CITY = 'CITY',
    CLUSTER = 'CLUSTER',
    CONTENT_CATEGORY = 'CONTENT_CATEGORY',
    COUNTRY = 'COUNTRY',
    CUSTOM_SITE_CONTEXTUAL = 'CUSTOM_SITE_CONTEXTUAL',
    DOMAIN = 'DOMAIN',
    DOOH_LOCATION = 'DOOH_LOCATION',
    FREQUENCY_CAPPING = 'FREQUENCY_CAPPING',
    GENERIC = 'GENERIC',
    GRAPH_CAPPING = 'GRAPH_CAPPING',
    IAS_APP_CONTEXTUAL = 'IAS_APP_CONTEXTUAL',
    IAS_SITE_CONTEXTUAL = 'IAS_SITE_CONTEXTUAL',
    IAS_SITE_CONTEXT_CONTROL_AVOIDANCE = 'IAS_SITE_CONTEXT_CONTROL_AVOIDANCE',
    IAS_SITE_CONTEXT_CONTROL_TARGETING = 'IAS_SITE_CONTEXT_CONTROL_TARGETING',
    IAS_APP_INVALID_TRAFFIC = 'IAS_APP_INVALID_TRAFFIC',
    IAS_SITE_INVALID_TRAFFIC = 'IAS_SITE_INVALID_TRAFFIC',
    INTERSTITIAL = 'INTERSTITIAL',
    LANGUAGE = 'LANGUAGE',
    ODC_BRAND_SAFETY_CONTEXTUAL = 'ODC_BRAND_SAFETY_CONTEXTUAL',
    ODC_CONTEXT_CONTEXTUAL = 'ODC_CONTEXT_CONTEXTUAL',
    ODC_INVALID_TRAFFIC_CONTEXTUAL = 'ODC_INVALID_TRAFFIC_CONTEXTUAL',
    ODC_LANGUAGE_CONTEXTUAL = 'ODC_LANGUAGE_CONTEXTUAL',
    ODC_VIEWABILITY_CONTEXTUAL = 'ODC_VIEWABILITY_CONTEXTUAL',
    OS = 'OS',
    OTTO_PNTA_CUSTOM = 'OTTO_PNTA_CUSTOM',
    OTTO_PNTA_GEO = 'OTTO_PNTA_GEO',
    OTTO_PNTA_INTENT = 'OTTO_PNTA_INTENT',
    OTTO_PNTA_PURCHASE = 'OTTO_PNTA_PURCHASE',
    OTTO_PNTA_SOZIO = 'OTTO_PNTA_SOZIO',
    PLATFORM = 'PLATFORM',
    POLYGON = 'POLYGON',
    POSTAL_CODE = 'POSTAL_CODE',
    PROFILE = 'PROFILE',
    PROGRAMMATIC_TV_CHANNEL = 'PROGRAMMATIC_TV_CHANNEL',
    PROVIDER = 'PROVIDER',
    REDIRECT_CONTENT_UNIT = 'REDIRECT_CONTENT_UNIT',
    SEVEN_ONE_AUDIENCES = 'SEVEN_ONE_AUDIENCES',
    SSP = 'SSP',
    STATE = 'STATE',
    TAGGING = 'TAGGING',
    TIME = 'TIME',
    TV_CHANNEL = 'TV_CHANNEL',
    TV_TARGETING_GROUP = 'TV_TARGETING_GROUP',
    UNSUPPORTED = 'UNSUPPORTED',
    URL = 'URL',
    VIDEO_PLAYER = 'VIDEO_PLAYER',
    VIDEO_POSITION = 'VIDEO_POSITION',
    VISIBILITY = 'VISIBILITY',
    WEATHER = 'WEATHER',
    WEISCHER_AUDIENCES_CUSTOM = 'WEISCHER_AUDIENCES_CUSTOM',
    WEISCHER_AUDIENCES_DOOH_TV_BOOST = 'WEISCHER_AUDIENCES_DOOH_TV_BOOST',
    WEISCHER_AUDIENCES_TRACE = 'WEISCHER_AUDIENCES_TRACE',
    WIFI = 'WIFI'
}

export {FilterTypes};
