import {Filter} from '../types/filter';
import {IasSiteContextControlAvoidanceFilter} from './ias-site-context-control-avoidance-filter';
import {IasSiteContextControlTargetingFilter} from './ias-site-context-control-targeting-filter';

type IasSiteContextControl = IasSiteContextControlTargetingFilter | IasSiteContextControlAvoidanceFilter;

function iasSiteContextControl(filter: Filter): filter is IasSiteContextControl {
    return filter instanceof IasSiteContextControlTargetingFilter || filter instanceof IasSiteContextControlAvoidanceFilter;
}

export {IasSiteContextControl, iasSiteContextControl};
