import {
    ChangeDetectionStrategy,
    Component,
    effect,
    input,
    InputSignal,
    signal,
    WritableSignal,
} from '@angular/core';
import {LibsManualService} from './manual.service';
import {ManualLinkKey} from './manual-link-keys.config';
import {Observable} from 'rxjs';
import {IManualPageContent, ManualColor} from '@angular-clan/core/manual';

@Component({
    selector: 'libs-manual',
    templateUrl: './manual.html',
    styleUrls: ['./manual.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
class LibsManualComponent {
    public key: InputSignal<ManualLinkKey> = input<ManualLinkKey>(ManualLinkKey.HomePage);
    public color: InputSignal<ManualColor> = input<ManualColor>('grey');
    public pageContent: WritableSignal<null | Observable<IManualPageContent>> = signal(null);
    public pageLink: WritableSignal<string> = signal('');

    public constructor(
        private manualService: LibsManualService,
    ) {
        effect(() => {
            this.pageLink.set(this.manualService.getExternalLink(this.key()));
            this.pageContent.set(this.manualService.getManualContentByKey(this.key()));
        }, {allowSignalWrites: true});
    }
}

export {LibsManualComponent, ManualColor};
