import {InjectionToken, Type} from '@angular/core';

interface ISearchPopupData {
    searchQuery?: string;
    category?: string;
    isSidebarOpened?: boolean;
}

interface ISearchInputSearchEvent {
    value: string;
}

abstract class LibsSearchPopupModule {
    public abstract getComponent(): Type<unknown>;
}

interface ISearchPopupModule {
    module: Promise<{AaSearchPopupModule: Type<LibsSearchPopupModule>}>;
}
const SEARCH_POPUP_MODULE: InjectionToken<ISearchPopupModule> = new InjectionToken('search.popup.module');

export {
    ISearchPopupData,
    ISearchInputSearchEvent,
    LibsSearchPopupModule,
    SEARCH_POPUP_MODULE,
    ISearchPopupModule,
};
