import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    Inject,
    LOCALE_ID,
    OnInit,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import {IWebsocketAnnouncementEventData, LibsWebsocketService} from '@active-agent/websocket';
import {parseMarkdown} from '@active-agent/markdown';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatToolbar} from '@angular/material/toolbar';
import {CommonsStatusIconModule} from '../status-icon/status-icon.module';
import {Status} from '../status-icon/status-icon.component';

@Component({
    selector: 'libs-announcement-header',
    templateUrl: 'announcement-header.component.html',
    styleUrls: ['./announcement-header.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatToolbar,
        CommonsStatusIconModule,
    ],
})
class LibsAnnouncementHeaderComponent implements OnInit {
    public infoStatus: Status = Status.Info;
    public message: WritableSignal<null | string> = signal(null);
    public isContentVisible: Signal<boolean> = computed(() => {
        return this.message() !== null;
    });

    public constructor(
        private websocketService: LibsWebsocketService,
        @Inject(LOCALE_ID) public locale: string,
        private destroyRef: DestroyRef,
    ) {}

    public ngOnInit(): void {
        this.websocketService.getAnnouncementMessage()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (eventData: IWebsocketAnnouncementEventData): void => {
                    const message: string | undefined = eventData.data && eventData.data[this.locale].trim();
                    this.message.set(message ? parseMarkdown(message) : null);
                },
            });
    }

}

export {LibsAnnouncementHeaderComponent};
