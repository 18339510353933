import {
    AdsquareFilter,
    AuctionTypeFilter,
    AudienceGraphCappingFilter,
    AudioFilter,
    BrowserFilter,
    CappingFilter, CityFilter,
    ContentCategoryFilter,
    CountryFilter,
    CustomSiteContextualFilter, DomainFilter,
    DoohLocationFilter,
    Filter,
    FilterTypes,
    FrequencyCappingFilter,
    IasFilter,
    IasInvalidTrafficFilter,
    iasSiteContextControl,
    IasSiteContextControl,
    InterstitialFilter,
    isIasFilter,
    isIasInvalidTrafficFilter,
    LanguageFilter,
    OdcBrandSafetyContextualFilter,
    OdcContextContextualFilter,
    OdcInvalidTrafficContextualFilter,
    OdcLanguageContextualFilter,
    OdcViewabilityContextualFilter,
    OperatingSystemsFilter,
    OttoPntaFilter,
    PlatformFilter,
    PolygonFilter, PostalCodeFilter,
    ProfileFilter,
    ProgrammaticTvChannelFilter, ProviderFilter,
    SspFilter,
    StateFilter,
    TaggingFilter,
    TimeFilter,
    TvChannelFilter,
    TvTargetingGroupFilter,
    UrlFilter,
    VideoPlayerFilter,
    VideoPositionFilter,
    VisibilityFilter, WeatherFilter, WeischerAudiencesFilter,
    WifiFilter,
} from '.';

class FilterTypeHelper {

    public isAudienceGraphCappingFilter(filter: Filter): filter is AudienceGraphCappingFilter {
        return filter.type === FilterTypes.GRAPH_CAPPING;
    }

    public isCappingFilter(filter: Filter): filter is CappingFilter {
        return filter.type === FilterTypes.CAPPING;
    }

    public isOdcContextContextualFilter(filter: Filter): filter is OdcContextContextualFilter {
        return filter.type === FilterTypes.ODC_CONTEXT_CONTEXTUAL;
    }

    public isOdcBrandSafetyContextualFilter(filter: Filter): filter is OdcBrandSafetyContextualFilter {
        return filter.type === FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL;
    }

    public isOdcLanguageContextualFilter(filter: Filter): filter is OdcLanguageContextualFilter {
        return filter.type === FilterTypes.ODC_LANGUAGE_CONTEXTUAL;
    }

    public isOdcInvalidTrafficContextualFilter(filter: Filter): filter is OdcInvalidTrafficContextualFilter {
        return filter.type === FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL;
    }

    public isOdcViewabilityContextualFilter(filter: Filter): filter is OdcViewabilityContextualFilter {
        return filter.type === FilterTypes.ODC_VIEWABILITY_CONTEXTUAL;
    }

    public isAdsquareFilter(filter: Filter): filter is AdsquareFilter {
        return filter.type === FilterTypes.ADSQUARE_GEO_CONTEXTUAL;
    }

    public isIasFilter(filter: Filter): filter is IasFilter {
        return isIasFilter(filter);
    }

    public iasSiteContextControlFilter(filter: Filter): filter is IasSiteContextControl {
        return iasSiteContextControl(filter);
    }

    public isIasInvalidTrafficFilter(filter: Filter): filter is IasInvalidTrafficFilter {
        return isIasInvalidTrafficFilter(filter);
    }

    public isFrequencyCapping(filter: Filter): filter is FrequencyCappingFilter {
        return filter.type === FilterTypes.FREQUENCY_CAPPING;
    }

    public isOperatingSystemsFilter(filter: Filter): filter is OperatingSystemsFilter {
        return filter.type === FilterTypes.OS;
    }

    public isSspFilter(filter: Filter): filter is SspFilter {
        return filter.type === FilterTypes.SSP;
    }
    public isBrowserFilter(filter: Filter): filter is BrowserFilter {
        return filter.type === FilterTypes.BROWSER;
    }

    public isCountryFilter(filter: Filter): filter is CountryFilter {
        return filter.type === FilterTypes.COUNTRY;
    }

    public isLanguageFilter(filter: Filter): filter is LanguageFilter {
        return filter.type === FilterTypes.LANGUAGE;
    }

    public isTvChannelFilter(filter: Filter): filter is TvChannelFilter {
        return filter.type === FilterTypes.TV_CHANNEL;
    }

    public isProgrammaticTvChannelFilter(filter: Filter): filter is ProgrammaticTvChannelFilter {
        return filter.type === FilterTypes.PROGRAMMATIC_TV_CHANNEL;
    }

    public isPlatformFilter(filter: Filter): filter is PlatformFilter {
        return filter.type === FilterTypes.PLATFORM;
    }

    public isDoohLocationFilter(filter: Filter): filter is DoohLocationFilter {
        return filter.type === FilterTypes.DOOH_LOCATION;
    }

    public isUrlFilter(filter: Filter): filter is UrlFilter {
        return filter.type === FilterTypes.URL;
    }

    public isStateFilter(filter: Filter): filter is StateFilter {
        return filter.type === FilterTypes.STATE;
    }

    public isWifiFilter(filter: Filter): filter is WifiFilter {
        return filter.type === FilterTypes.WIFI;
    }

    public isInterstitialFilter(filter: Filter): filter is InterstitialFilter {
        return filter.type === FilterTypes.INTERSTITIAL;
    }

    public isCustomSiteContextualFilter(filter: Filter): filter is CustomSiteContextualFilter {
        return filter.type === FilterTypes.CUSTOM_SITE_CONTEXTUAL;
    }

    public isPolygonFilter(filter: Filter): filter is PolygonFilter {
        return filter.type === FilterTypes.POLYGON;
    }

    public isOttoPntaFilter(filter: Filter): filter is OttoPntaFilter {
        return filter instanceof OttoPntaFilter;
    }

    public isVideoPositionFilter(filter: Filter): filter is VideoPositionFilter {
        return filter instanceof VideoPositionFilter;
    }

    public isVisibilityFilter(filter: Filter): filter is VisibilityFilter {
        return filter instanceof VisibilityFilter;
    }

    public isAuctionTypeFilter(filter: Filter): filter is AuctionTypeFilter {
        return filter instanceof AuctionTypeFilter;
    }
    public isTimeFilter(filter: Filter): filter is TimeFilter {
        return filter instanceof TimeFilter;
    }
    public isProfileFilter(filter: Filter): filter is ProfileFilter {
        return filter instanceof ProfileFilter;
    }
    public isTaggingFilter(filter: Filter): filter is TaggingFilter {
        return filter instanceof TaggingFilter;
    }
    public isTvTargetingGroupFilter(filter: Filter): filter is TvTargetingGroupFilter {
        return filter instanceof TvTargetingGroupFilter;
    }
    public isAudioFilter(filter: Filter): filter is AudioFilter {
        return filter instanceof AudioFilter;
    }
    public isContentCategoryFilter(filter: Filter): filter is ContentCategoryFilter {
        return filter instanceof ContentCategoryFilter;
    }
    public isVideoPlayerFilter(filter: Filter): filter is VideoPlayerFilter {
        return filter instanceof VideoPlayerFilter;
    }
    public isProviderFilter(filter: Filter): filter is ProviderFilter {
        return filter instanceof ProviderFilter;
    }
    public isWeatherFilter(filter: Filter): filter is WeatherFilter {
        return filter instanceof WeatherFilter;
    }

    public isDomainFilter(filter: Filter): filter is DomainFilter {
        return filter.type === FilterTypes.DOMAIN;
    }

    public isPostalCodeFilter(filter: Filter): filter is PostalCodeFilter {
        return filter instanceof PostalCodeFilter;
    }

    public isCityFilter(filter: Filter): filter is CityFilter {
        return filter instanceof CityFilter;
    }

    public isWeischerAudiencesFilter(filter: Filter): filter is WeischerAudiencesFilter {
        return filter instanceof WeischerAudiencesFilter;
    }
}

export {FilterTypeHelper};
