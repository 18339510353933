import {Injectable} from '@angular/core';
import {ISlowLogData, LibsEventLoggerService, LibsSlowLog} from '@active-agent/event';
import {ProjectTypes, CommonsError, FrontendServicesEndpoints} from '@active-agent/types';
import {environment} from '../../../environments/environment';
import {retry} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
class EventLoggerService extends LibsEventLoggerService {
    protected version: string = environment.version;
    protected project: ProjectTypes = ProjectTypes.Booking;

    public logSlowLog(slowLog: LibsSlowLog): void {
        const data: ISlowLogData = slowLog.getData();

        if (
            !environment.servicesUrl
            || (data.url.includes('report') && data.time < environment.slowLoggerThresholdReportingInMinutes)
            || data.time < environment.slowLoggerThresholdInMinutes
        ) {
            return;
        }

        this.httpClient
            .post(`${environment.servicesUrl}/${FrontendServicesEndpoints.SlowLogger}`, data)
            .pipe(
                retry({
                    count: 3,
                    delay: 7_000,
                    resetOnSuccess: true,
                }),
            )
            .subscribe({
                error: (error: Error) => {
                    void this.errorHandler?.handle(
                        new CommonsError(
                            'error when sending event log to event logger', {cause: error, data: {slowLog, error}}),
                        false,
                    );
                },
            });
    }
}

export {EventLoggerService};
