import {IEnvironment} from './type';
import packageJson from './package.json';

const environment: IEnvironment = {
    production: true,
    apiUrl: 'https://api.active-agent.com/latest',
    debug: false,
    sentryUrl: 'https://7c2982f043304f08bf46ea7fd860aa93@error.active-agent.com/5',
    servicesUrl: 'https://services.active-agent.com',
    websocketUrl: 'wss://services.active-agent.com',
    slowLoggerThresholdInMinutes: 1,
    slowLoggerThresholdReportingInMinutes: 5,
    version: packageJson.version,
    leafletMapProvider: {
        templateUrl: 'https://tile.thunderforest.com/virtualminds-ou5/{z}/{x}/{y}.png?apikey=089cd2ef2cfb449581396234a659edd1',
        attribution: 'Maps © <a href="https://www.thunderforest.com">Thunderforest</a>, Data © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    },
};

export {environment};
