import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Location} from '@angular/common';
import {throwError} from 'rxjs';
import {CoreCookieService} from '@angular-clan/core';
import {environment} from '../../../environments/environment';
import {
    FrontendServicesEndpoints,
    ILibsDspEnvironment,
    LogoutReason,
    UnauthorizedRequestError,
} from '@active-agent/types';
import {EventLoggerService} from '../event/event-logger.service';
import {Router} from '@angular/router';
import {AppPath} from '../enums';
import {DspApiRequestInterceptorService} from '@active-agent/api';

@Injectable({
    providedIn: 'root',
})
class ApiRequestInterceptorService extends DspApiRequestInterceptorService {
    public static userLoggedOutReason: LogoutReason | null = null;

    protected environment: ILibsDspEnvironment = environment;
    protected urlsToIntercept: Array<string> = [
        environment.apiUrl,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.PartnerMappings}`,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.ManualPosts}`,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.ManualMenu}`,
        `${environment.servicesUrl}/${FrontendServicesEndpoints.ManualSearch}`,
    ];

    constructor(
        private location: Location,
        cookieService: CoreCookieService,
        eventLogger: EventLoggerService,
        http: HttpClient,
        private router: Router,
    ) {
        super(cookieService, eventLogger, http);
    }

    protected handleRequestError(rejection: HttpErrorResponse, newRequest: HttpRequest<unknown>, startTime: Date) {
        if (!rejection.url || !rejection.url.includes(environment.apiUrl)) {
            return throwError(() => rejection);
        }
        this.logSlowRequest(newRequest, startTime);

        if (rejection.status === HttpStatusCode.Unauthorized) {
            ApiRequestInterceptorService.userLoggedOutReason = LogoutReason.Unauthorized;

            /**
             * If already on the login page and requests still failing we don't need to redirect again
             */
            if (!this.location.path().startsWith(`/${AppPath.Login}`)) {
                void this.router.navigate([AppPath.Logout], {
                    queryParams: {
                        redirectTo: this.location.path(),
                    },
                });
            }

            return throwError(() => new UnauthorizedRequestError('api request failed with unauthorized status', {cause: rejection}));

        }

        return throwError(() => rejection);
    }
}

export {ApiRequestInterceptorService};
